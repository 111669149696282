<template id="svg-229_34543">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_229_34543)">
            <path d="M24 12.8306C24 6.20315 18.6274 0.830566 12 0.830566C5.37258 0.830566 0 6.20315 0 12.8306C0 18.82 4.3882 23.7846 10.125 24.6848V16.2993H7.07812V12.8306H10.125V10.1868C10.125 7.17932 11.9166 5.51807 14.6576 5.51807C15.9701 5.51807 17.3438 5.75244 17.3438 5.75244V8.70557H15.8306C14.34 8.70557 13.875 9.63064 13.875 10.5806V12.8306H17.2031L16.6711 16.2993H13.875V24.6848C19.6118 23.7846 24 18.82 24 12.8306Z"
                  fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_229_34543">
                <rect width="24" height="24" fill="white" transform="translate(0 0.830566)"/>
            </clipPath>
        </defs>
    </svg>
</template>
