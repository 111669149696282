<template>
  <svg fill="none" height="25" viewBox="0 0 24 25" width="24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_229_34542)">
      <path
          d="M22.2234 0.830566H1.77187C0.792187 0.830566 0 1.604 0 2.56025V23.0962C0 24.0524 0.792187 24.8306 1.77187 24.8306H22.2234C23.2031 24.8306 24 24.0524 24 23.1009V2.56025C24 1.604 23.2031 0.830566 22.2234 0.830566ZM7.12031 21.2821H3.55781V9.82588H7.12031V21.2821ZM5.33906 8.26494C4.19531 8.26494 3.27188 7.3415 3.27188 6.20244C3.27188 5.06338 4.19531 4.13994 5.33906 4.13994C6.47813 4.13994 7.40156 5.06338 7.40156 6.20244C7.40156 7.33682 6.47813 8.26494 5.33906 8.26494ZM20.4516 21.2821H16.8937V15.7134C16.8937 14.3868 16.8703 12.6759 15.0422 12.6759C13.1906 12.6759 12.9094 14.1243 12.9094 15.6196V21.2821H9.35625V9.82588H12.7687V11.3915H12.8156C13.2891 10.4915 14.4516 9.53994 16.1813 9.53994C19.7859 9.53994 20.4516 11.9118 20.4516 14.9962V21.2821V21.2821Z"
          fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_229_34542">
        <rect fill="white" height="24" transform="translate(0 0.830566)" width="24"/>
      </clipPath>
    </defs>
  </svg>
</template>
